import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { BLE } from '@ionic-native/ble/ngx';
import { Environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class BluetoothProvider {

  SERVICE_UUID = "10001800-0000-1000-8000-00805f9b34fb";
  CHARACTERISTIC_UUID = "10002a00-0000-1000-8000-00805f9b34fb";

  // BgDriverTabletのスキャンを停止する時間。単位は、ミリ秒。
  SCAN_STOP_TIME = 10000;

  scanning: boolean = false;
  enabled: boolean = false;

  constructor(private ble: BLE,
    private platform: Platform) {
    this.bluetoothEnabled();
  }

  // 端末探索
  scanDevice(enable: boolean, bleCallback: any) {
    if (this.enabled) {
      return;
    }

    this.ble.isEnabled().then((bleEnabled) => {
      if (enable) {
        if (this.scanning) {
          // 既にBLE Device Scan中
          console.log("ble during the scanning...");
          return;
        }
        console.log("start scan ble device");

        // BLE Device Scan開始
        this.scanning = true;
        /** SEQ-07-01 スキャン開始 */
        this.ble.startScan([this.SERVICE_UUID]).subscribe((device) => {
          // BLE Device 接続
          console.log("[OK] start scan ble device");
          /** SEQ-07-01 コネクション接続 */
          this.startConnection(device.id, bleCallback);

        }, (error) => {
          this.scanning = false;
          console.log("[NG] start scan ble device. error: " + error);
        });

        console.log("ble setTimeout callback is:" + this.SCAN_STOP_TIME);
        // 指定時間後にスキャンを停止する
        let self = this;
        setTimeout(function () {
          self.stopScanDevice();
        }, this.SCAN_STOP_TIME);
      } else {
        this.stopScanDevice();
      }

    }, (bleDisabled) => {
      // Bluetooth機能を有効にする
      this.bluetoothEnabled();
      console.log("ble scanning set false");
      this.scanning = false;
    });
  }

  disconnect(device_id) {
    console.log("call ble disconnect.");
    this.ble.isConnected(device_id).then((success) => {
      console.log("ble disconnect done.");
      this.ble.disconnect(device_id);
    }, (failure) => {
      console.log("ble disconnect failure");
    });
  }

  stopScanDevice() {
    if (!this.scanning) {
      console.log("stopScanDevice() interruption. ble no scanning...");
      return;
    }

    try {
      console.log("stop scan ble device");
      this.ble.stopScan();
    } catch (e) {
      console.log(e);
    }

    this.scanning = false;
  }

  // 接続開始
  startConnection(device_id, bleCallback: any) {
    this.ble.connect(device_id).subscribe((success) => {
      console.log("[OK] connect ble device: " + device_id);
      bleCallback(device_id);
    }, (error) => {
      console.log("[NG] connect ble device: " + device_id + ", error: " + error);
    });
  }

  // メッセージ送信
  sendMsg(device_id, data, bleCallback: any) {
    this.ble.write(
      device_id,
      this.SERVICE_UUID,
      this.CHARACTERISTIC_UUID,
      this.stringToBytes(data)
    ).then((success) => {
      console.log("[OK] send message ble device: " + device_id + ', data: ' + data);
      bleCallback(true, device_id);
    }, (error) => {
      console.log("[NG] send message ble device: " + device_id + ", error: " + error);
      bleCallback(false, device_id);
    });
  }

  // バッファを文字列に変換
  // ASCII only
  bytesToString(buffer) {
    return String.fromCharCode.apply(null, new Uint8Array(buffer));
  }

  // 文字列をバッファに変換
  // ASCII only
  stringToBytes(data: string) {
    return new Uint8Array([].map.call(data, function (item) {
      return item.charCodeAt(0);
    })).buffer;
  }

  // bluetooth有効化
  bluetoothEnabled() {
    if (!Environment.isEnabledBle) { return; }

    this.enabled = true;
    this.ble.enable().then((success) => {
      this.enabled = false;
    }, (failed) => {
      this.bluetoothEnabled();
    });

  }

}
