import { MaintUser } from './../providers/servicer/models/maint-user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Environment } from '../../environments/environment';

import { Station } from './servicer/models/station';
import { UserLoginV2ApiResponse } from './servicer/models/user-login-v2-api-response';
import { UserOption } from './servicer/models/user-option';
import { Cargo } from './servicer/models/cargo';
import { RoutingAdditionalInfo } from './servicer/models/routing-additional-info';
import { UserStationsV2ApiResponse } from './servicer/models/user-stations-v2-api-response';
import { UserVehiclesV2ApiResponse } from './servicer/models/user-vehicles-v2-api-response';
import { UserPuStationCodesV2ApiResponse } from './servicer/models/user-pu-station-codes-v2-api-response';
import { UserDoStationCodesV2ApiResponse } from './servicer/models/user-do-station-codes-v2-api-response';
import { Const } from './const';
import { UserOptionItemNameType } from 'src/app/types/user-option-item-name-type';
import { UserAccoountServiceProvider } from 'src/app/providers/servicer/user-account-service';
import { GenericItem } from 'src/app/providers/servicer/models/generic-item';

@Injectable({ providedIn: 'root' })
export class HttpRequestor {

  /** ログイン時のユーザオプション. */
  userOption: UserOption;

  /** ステーションの情報. */
  station = {
    /** ステーションのリスト. */
    stations: [] as Station[],
    /** 必須ステーション. */
    requiredStation: null as Station,
    /** 必須ステーション範囲（単位 : メートル）. */
    requiredStationRadiusDistance: null as number,
    /** 自宅のステーション. */
    homeStation: null as Station,
    /** プライベートステーションのリスト. */
    privateStations: [] as Station[]
  };

  private base_url = Environment.baseUrl;

  constructor(
    public httpClient: HttpClient,
    private userAccoountServiceProvider: UserAccoountServiceProvider
  ) {}


  /**
   * サービサへログインリクエストを送信する
   * 
   * @remarks
   * 
   * ログインに成功した場合はユーザステーション一覧取得をリクエストする
   * ログインに失敗した場合はその時点でエラーを返却する
   * 
   * @param loginId ログインID
   * @param passwd パスワード
   * @param language 言語
   * @param app_version appバージョン
   * @param callback コールバック
   * @param auto 自動ログイン
   */
  postLoginRequest(
    loginId: string,
    passwd: string,
    language: string,
    app_version: string,
    callback: any,
    auto: boolean
  ) {
    const url = this.base_url + 'login';
    this.userOption = null;

    // POSTする際のパラメータ値
    const postParams =
      'login_id=' + loginId +
      '&password=' + passwd +
      '&language=' + language +
      '&app_version=' + app_version;

    let result: UserLoginV2ApiResponse;

    this.postRequestObservable(url, postParams)
      .pipe(map((response: UserLoginV2ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }

        result = response;
        this.userOption = result.option;

        return response;
      }))
      .pipe(mergeMap(() => this.postStationsRequest(result.user_id.toString()))) /** SEQ-01-05 ステーション一覧取得リクエスト */
      .subscribe(
        () => this.execCallback(callback, result, 0, -1, auto),
        () => this.execCallback(callback, "", -1, -1, auto)
      );
  }

  /**
   * ユーザステーション一覧取得.
   * 
   * @remarks
   * 
   * SEQ-03-02 ステーション一覧取得リクエスト
   * 
   * @param userId ユーザID
   * @returns Observable<UserStationsV2ApiResponse>
   */
  postStationsRequest(
    userId: string
  ): Observable<UserStationsV2ApiResponse> {
    const url = this.base_url + 'stations';

    // POSTする際のパラメータ値
    const postParams = 'user_id=' + userId;

    /** SEQ-03-02 ステーション一覧取得リクエスト */
    return this.postRequestObservable(url, postParams)
      .pipe(map((response: UserStationsV2ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }

        /** SEQ-03-02 ステーション一覧を保存 */
        this.setStation(response.stations);

        return response;
      }));
  }

  /**
   * [ユーザ]近地乗車ステーション一覧取得（v2/user/puStationCodes）.
   * 
   * @param userId ユーザID
   * @param lat 緯度
   * @param lon 経度
   * @returns Observable<UserPuStationCodesV2ApiResponse>
   */
   postPuStationCodesRequest(userId: string, lat: number, lon: number ): Observable<UserPuStationCodesV2ApiResponse> {
    const url = this.base_url + 'puStationCodes';

    // POSTする際のパラメータ値
    const postParams = 
      'userId=' + userId +
      '&lat=' + lat +
      '&lon=' + lon;

    /** SEQ-03-01 近地乗車ステーション一覧取得リクエスト */
    return this.postRequestObservable(url, postParams)
      .pipe(map((response: UserPuStationCodesV2ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }
        return response;
      }));
  }

  /**
   * [ユーザ]降車ステーション一覧取得（v2/user/doStationCodes）.
   * 
   * @param userId ユーザID
   * @param lat 緯度
   * @param lon 経度
   * @returns Observable<UserDoStationCodesV2ApiResponse>
   */
   postDoStationCodesRequest(userId: string, puStationCode: string ): Observable<UserDoStationCodesV2ApiResponse> {
    console.log("postDoStationCodesRequest puStationCode " + puStationCode);
    const url = this.base_url + 'doStationCodes';

    // POSTする際のパラメータ値
    const postParams = 
      'userId=' + userId +
      '&puStationCode=' + puStationCode;

    /** SEQ-03-03 降車ST一覧取得リクエスト */
    return this.postRequestObservable(url, postParams)
      .pipe(map((response: UserDoStationCodesV2ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }
        return response;
      }));
  }

  // 車両検索のリクエスト
  postSearchRequest(
    user_id: string,
    lat: string,
    lon: string,
    user_number: string,
    cargoCount: string,
    dest_lat: string,
    dest_lon: string,
    language: string,
    additionalInfo: RoutingAdditionalInfo,
    callback: any
  ) {
    let url = this.base_url + 'vehicleSearch';
    const userNum = user_number ? user_number : 0;
    const cargoCnt = cargoCount ? cargoCount : 0;
    const additionalInfoJson = JSON.stringify(this.convertAdditionalInfo(additionalInfo));

    // POSTする際のパラメータ値
    let postParams =
      'user_id=' + user_id +
      '&lat=' + lat +
      '&lon=' + lon +
      '&user_number=' + userNum +
      '&cargoCount=' + cargoCnt +
      '&dest_lat=' + dest_lat +
      '&dest_lon=' + dest_lon +
      '&language=' + language +
      '&additionalInfo=' + encodeURIComponent(additionalInfoJson) +
      '&guidance_destination=false';

    /** SEQ-04-0A 車両検索リクエスト  */
    this.postRequest(url, postParams, callback, -1, false);
  }

  /**
   * additionalInfoの変換(null判定).
   * @param additionalInfo 荷物の追加情報
   */
  private convertAdditionalInfo(additionalInfo: RoutingAdditionalInfo): RoutingAdditionalInfo {
    const routingAdditionalInfo: RoutingAdditionalInfo = {
      cargoes: null,
      puStationCode: null,
      doStationCode: null
    };
    
    if (!additionalInfo) {
      return null;
    }
    if(additionalInfo.cargoes){
      const cargoExist: Cargo[] = additionalInfo.cargoes.filter(cargo => cargo.cargoId || cargo.ownerId);
      if (cargoExist.length > 0) {
        routingAdditionalInfo.cargoes = cargoExist;
      }
    }
    routingAdditionalInfo.doStationCode = additionalInfo.doStationCode;
    routingAdditionalInfo.puStationCode = additionalInfo.puStationCode;

    return routingAdditionalInfo;
  }


  // 配車指示のリクエスト
  postDispatchRequest(
    user_id: string,
    vehicle_id: string,
    lat: string,
    lon: string,
    accuracy: string,
    language: string,
    callback: any
  ) {
    let url = this.base_url + 'dispatchRequest';

    // POSTする際のパラメータ値
    let postParams =
      'user_id=' + user_id +
      '&vehicle_id=' + vehicle_id +
      '&lat=' + lat +
      '&lon=' + lon +
      '&accuracy=' + accuracy +
      '&language=' + language;

    /** SEQ-04-0A 配車指示リクエスト */
    this.postRequest(url, postParams, callback, -1, false);
  }

  // 配車確認のリクエスト
  postDispatchConfirmationRequest(
    user_id: string,
    language: string,
    callback: any,
    lat?: number,
    lon?: number,
    accuracy?: number
  ) {
    let url = this.base_url + 'dispatchConfirmation';

    // POSTする際のパラメータ値
    let postParams =
      'user_id=' + user_id +
      '&language=' + language;

    if (lat != null && lon != null && accuracy != null) {
      postParams = postParams + '&lat=' + lat +
        '&lon=' + lon +
        '&accuracy=' + accuracy;
    }

    this.postRequest(url, postParams, callback, -1, false);
  }

  // 目的地到着のリクエスト
  postArrivedDestinationRequest(
    user_id: string,
    lat: string,
    lon: string,
    accuracy: string,
    language: string,
    callback: any
  ) {
    let url = this.base_url + 'arrivedDestination';

    // POSTする際のパラメータ値
    let postParams =
      'user_id=' + user_id +
      '&lat=' + lat +
      '&lon=' + lon +
      '&accuracy=' + accuracy +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  // 配車キャンセルのリクエスト
  postDispatchCancelRequest(
    user_id: string,
    vehicle_id: string,
    lat: string,
    lon: string,
    accuracy: string,
    language: string,
    callback: any
  ) {
    let url = this.base_url + 'dispatchCancel';

    // POSTする際のパラメータ値
    let postParams =
      'user_id=' + user_id +
      '&vehicle_id=' + vehicle_id +
      '&lat=' + lat +
      '&lon=' + lon +
      '&accuracy=' + accuracy +
      '&language=' + language;

    /** SEQ-17-03 配車キャンセルリクエスト */
    this.postRequest(url, postParams, callback, -1, false);
  }

  /**
   * 車両一覧取得.
   * 
   * @param userId ユーザID
   * @returns Observable<UserVehiclesV2ApiResponse>
   */
  postVehicleListRequest(
    userId: string,
  ): Observable<UserVehiclesV2ApiResponse> {
    const url = this.base_url + 'vehicles';

    // POSTする際のパラメータ値
    const postParams = 'user_id=' + userId;

    /** SEQ-01-05 車両一覧取得リクエスト */
    return this.postRequestObservable(url, postParams);
  }

  // ユーザステータス到着変更のリクエスト
  postUserArrivedRequest(
    user_id: string,
    vehicle_id: string,
    language: string,
    callback: any
  ) {
    let url = this.base_url + 'arrived';

    // POSTする際のパラメータ値
    let postParams =
      'user_id=' + user_id +
      '&vehicle_id=' + vehicle_id +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  // アンケート設問
  // question_id :
  //  1 (予約前キャンセルアンケート)
  //  2 (予約後キャンセルアンケート)
  //  3 (降車後アンケート)
  postQuestionReceiveServlet(
    user_id: string,
    question_id: string,
    language: string,
    callback: any
  ) {
    let url = this.base_url + 'questionReceive';

    // POSTする際のパラメータ値
    let postParams =
      'user_id=' + user_id +
      '&question_id=' + question_id +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  // アンケート投稿
  // question_id :
  //  0 (予約前キャンセルアンケート)
  //  1 (予約後キャンセルアンケート)
  //  2 (降車後アンケート)
  postQuestionAnswerServlet(
    question_id: string,
    user_id: string,
    language: string,
    answers: string,
    callback: any
  ) {
    // answers
    // ・予約前アンケート
    //   { "answers" : [　{"question_no": 0, "answer_no":0, answer_result:"0"}, {"question_no": 0, "answer_no":1, answer_result:"1"}, {"question_no": 0, "answer_no":2, answer_result:"1"}, {"question_no": 0, "answer_no":3, answer_result:"0"}, {"question_no": 0, "answer_no":4, answer_result:"0"}, {"question_no": 0, "answer_no":5, answer_result:"0"}, {"question_no": 0, "answer_no":6, answer_result:"0"} ] }
    // ・予約後アンケート
    //   { "answers" : [　{"question_no": 0, "answer_no":0, answer_result:"0"}, {"question_no": 0, "answer_no":1, answer_result:"1"}, {"question_no": 0, "answer_no":2, answer_result:"1"}, {"question_no": 0, "answer_no":3, answer_result:"0"} ] }
    // ・乗車完了アンケート
    //   { "answers" : [　{"question_no": 0, "answer_no":0, answer_result:"３"}, {"question_no": 1, "answer_no":0, answer_result:"2"}, {"question_no": 2, "answer_no":0, answer_result:"2"}, {"question_no": 3, "answer_no":0, answer_result:"1"}, {"question_no": 4, "answer_no":0, answer_result:"4"} ] }

    let url = this.base_url + 'questionAnswer';

    // POSTする際のパラメータ値
    let postParams =
      'question_id=' + question_id +
      '&user_id=' + user_id +
      '&language=' + language +
      '&answers=' + encodeURIComponent(answers);

    this.postRequest(url, postParams, callback, -1, false);
  }

  // 予約確認
  postReservationConfirmRequest(
    user_id: string,
    language: string,
    callback: any
  ) {
    let url = this.base_url + 'reservationConfirm';

    // POSTする際のパラメータ値
    let postParams =
      'user_id=' + user_id +
      '&language=' + language;

    /** SEQ-01-05 予約確認リクエスト */
    this.postRequest(url, postParams, callback, -1, false);
  }

  // BLE API
  postBoardingCheckRequest(
    user_id: string,
    vehicle_id: string,
    mp_id: string,
    token: string,
    callback: any
  ) {
    let url = this.base_url + 'boardingCheck';

    // POSTする際のパラメータ値
    let postParams =
      'user_id=' + user_id +
      '&vehicle_id=' + vehicle_id +
      '&mp_id=' + mp_id +
      '&token=' + token;

    /** SEQ-07-01 乗車認証リクエスト */
    this.postRequest(url, postParams, callback, -1, false);
  }

  postPlacePredictions(
    user_id: string,
    input: string,
    callback: any,
    option?: { //候補地としての優先度を上げたい地域を指定
      range?: {
        lat: number,
        lng: number,
        radius: number
      },
      language?: string
    }
  ) {
    let url: string = this.base_url + 'placePredictions';

    let param: string =
      'user_id=' + user_id +
      "&input=" + input;
    param = this.setPlacePredictionsParamOption(param, option);

    this.postRequest(url, param, callback, 0, false);
  }

  setPlacePredictionsParamOption(param: string, option: any): string {
    if (option == null) {
      return param;
    }

    if (option.range != null &&
      option.range.lat != null &&
      option.range.lng != null &&
      option.range.radius != null) {
      param = param + "&lat=" + option.range.lat + "&lng=" + option.range.lng + "&radius=" + option.range.radius;
    }

    if (option.language != null && option.language !== "") {
      param = param + "&language=" + option.language;
    }

    return param;
  }

  postPlaceDetails(user_id: string, place_id: string, callback: any, language?: string) {
    let url: string = this.base_url + 'placeDetails';

    let param: string =
      'user_id=' + user_id +
      "&place_id=" + place_id;
    if (language != null) { param = param + '&language=' + language; }

    this.postRequest(url, param, callback, 0, false);
  }

  postReverseGeocode(user_id: string, lat: number, lng: number, callback: any, language?: string) {
    let url: string = this.base_url + 'reverseGeocode';

    let param: string =
      'user_id=' + user_id +
      "&lat=" + lat +
      "&lng=" + lng;
    if (language != null) { param = param + '&language=' + language; }

    this.postRequest(url, param, callback, 0, false);
  }

  postUserSettings(user_id: string, language: string, fcm_token: string, callback: any) {
    let url: string = this.base_url + 'settings';

    let param: string =
      'user_id=' + user_id +
      "&language=" + language +
      "&fcm_token=" + fcm_token;

    /** SEQ-01-05 設定更新リクエスト */
    this.postRequest(url, param, callback, 0, false);
  }

  // ポストリクエスト実行
  postRequest(
    url: string,
    postParams: string,
    callback: any,
    req_sts: number,
    auto: any
  ) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = {
      headers: headers,
    };

    console.log("send:" + postParams);

    this.httpClient
      .post(url, postParams, options)
      .toPromise()
      .then((data) => {
        try {
          if (data) {
            console.log("post: " + JSON.stringify(data));
          }
          this.execCallback(callback, data, 0, req_sts, auto);
        } catch (e) {
          console.log(e);
          this.execCallback(callback, data, -1, req_sts, auto);
        }
      })
      .catch((err) => {
        console.log("post error: " + err);
        this.execCallback(callback, "", -1, req_sts, auto);
      });
  }

  /**
   * POSTリクエスト実行（Observableで返却）.
   * 
   * @param url URL
   * @param params パラメータ
   * @returns Observable
   */
  postRequestObservable<V>(
    url: string,
    params: string
  ): Observable<V> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const options = {
      headers: headers,
    };

    return this.httpClient.post<V>(url, params, options);
  }

  private execCallback(callback, res, code, req_sts, auto) {
    try {
      callback(res, code, req_sts, auto);
    } catch (e) {
      console.log("execCallback error.");
      console.log(e);
    }
  }

  /**
   * ステーション関連の情報を設定します.
   * 
   * @param originalStations オリジナルのステーションのリスト
   * @returns ステーションのリスト
   */
  private setStation(originalStations: Station[]) {
    this.station.stations = originalStations;

    if (!this.userOption) {
      return;
    }

    const stations = this.toStationsSetCode(originalStations);

    this.station.requiredStation = this.toStation(this.userOption.requiredStationCode, stations);
    this.station.requiredStationRadiusDistance = this.userOption.requiredStationRadiusDistance;
    this.station.homeStation = this.toStation(this.userOption.homeStationCode, stations);
    this.station.privateStations = this.toStations(this.userOption.privateStationCodes, stations);
  }

  /**
   * ステーションのリストにコードを設定します.
   * 
   * @param originalStations オリジナルのステーションのリスト
   * @returns コードを設定したステーションのリスト
   */
  private toStationsSetCode(originalStations: Station[]): any {
    if (!originalStations) {
      return [];
    }

    const stations = [];

    originalStations.forEach((v) => {
      stations[v.code] = v;
    })

    return stations;
  }

  /**
   * ステーションのリストに変換します.
   * 
   * @param stationCodes ステーションコードのリスト
   * @param originalStations ステーションのリスト
   * @returns ステーションのリスト
   */
  private toStations(stationCodes: string[], originalStations: Station[]): any {
    if (!stationCodes) {
      return [];
    }

    const stations = [];

    stationCodes.forEach((stationCode) => {
      const station = originalStations[stationCode];

      if (station) {
        stations.push(station);
      }
    })

    return stations;
  }

  /**
   * ステーションに変換します.
   * 
   * 以下の場合、null を返却します.
   * - ステーションコードがない
   * - ステーションのリストにステーションコードがない
   * 
   * @param stationCode ステーションコード
   * @param stations ステーションのリスト
   * @returns ステーション
   */
  private toStation(stationCode: string, originalStations: Station[]): Station {
    if (!stationCode) {
      return null;
    }

    const station = originalStations[stationCode];

    if (!station) {
      return null;
    }

    return station;
  }

  /**
   * アカウント情報を取得し、userOptionとstationのデータを再取込
   * サービサに対し、ユーザの取得と、ステーション一覧の取得を実施している。
   * @param userId ユーザID
   */
  public fetchUserOptionAndStation(userId: number): Observable<GenericItem<MaintUser>>{
    /* SEQ-16-01 アカウント情報取得リクエスト */
    return this.userAccoountServiceProvider.get(userId).pipe(
      map((response)=>{
        this.userOption = {
          homeStationCode: response.item.options.find((v) => v.name === UserOptionItemNameType.HOME_STATION_CODE)?.value,
          privateStationCodes: response.item.options.find((v) => v.name === UserOptionItemNameType.PRIVATE_STATION_CODES)?.value.split(","),
          requestableReservation: response.item.options.find((v) => v.name === UserOptionItemNameType.REQUESTABLE_RESERVATION)?.value,
          requiredStationCode: response.item.options.find((v) => v.name === UserOptionItemNameType.REQUIRED_STATION_CODE)?.value,
          requiredStationRadiusDistance: Number(response.item.options.find((v) => v.name === UserOptionItemNameType.REQUIRED_STATION_RADIUS_DISTANCE)?.value),
        }  as UserOption;
        
        return response;
      }),
      map((response)=>{
        /** SEQ-03-02 ステーション一覧取得リクエスト */
        this.postStationsRequest(userId.toString()).subscribe();
        return response;
      })
    );
  }
}
