import { LanguageSetup } from '../language-setup';
import { HttpRequestor } from '../http-requestor';
import { LocationTracker } from '../location-tracker';
import { Observable, interval, Subscription } from 'rxjs';
import { Const } from '../const';
import { UserStatusStrategyInterface } from './user-status.strategy.interface';

export class UserStatusPolling implements UserStatusStrategyInterface {
    private observer: Subscription;
    private callback: any;

    private userId: string;

    constructor(public langSetup: LanguageSetup,
        public httpReq: HttpRequestor,
        public locationTracker: LocationTracker
    ) {
        this.observer = null;
    }

    initialize(callback: any, userId: string) {
        this.callback = callback;
        this.userId = userId;
    }

    execute() {
        this.observer = interval(2000).subscribe(() => {
            this.httpReq.postDispatchConfirmationRequest( /** SEQ-05-01 配車確認リクエスト */
                this.userId,
                this.langSetup.lang,
                this.statusReceiveCallback,
                this.locationTracker.getLat(),
                this.locationTracker.getLng(),
                this.locationTracker.getAccuracy()
            );
        });
    }

    statusReceiveCallback = (res, code, req_sts, auto) => {
        if (code !== Const.HTTP_REQUEST_CODE_SUCCESS || res === "") {
            throw new Error("postDispatchConfirmationRequest: HTTP Request Error.");
        }
        let json = res;
        if (json.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) { return; }

        this.callback(res);
    };

    finalize() {
        if (this.observer == null) { return; }
        this.observer.unsubscribe();
        this.observer = null;
    }
}