import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Environment } from './environments/environment';
import 'hammerjs';

if (Environment.production) {
  enableProdMode();
}

/**
 * SEQ-01-05 nimoアプリ起動
 * アプリが起動するとsrc/main.tsがエントリポイントとして読み込まれ、
 * 実行結果がsrc/index.htmlにレンダリングされる。
 * (main.tsとindex.htmlはangular.jsonで指定)
 * main.tsからはAppModuleが起動され、その後AppComponentが起動する。
 * AppComponentにはselectorとしてapp-rootが設定されており、この設定から
 * index.htmlの<app-root>タグ内にapp.component.htmlがレンダリングされる。
 * app.component.htmlでは、メニュー表示(ion-menuタグ)とメイン画面の表示を行っており、
 * メイン画面(ion-router-outletタグ内)に関してはAppRoutingModuleにより
 * 出力されるコンポーネントが制御される。Routingの設定上T1cLoginPageModuleが
 * 最初に実行されるようになっており、このモジュールに紐づくログイン画面(T1cLoginPage)
 * が表示される。
 */
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
